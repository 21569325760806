import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConstant } from '../utilities/app.constant';
import { BaseService } from './base.service';
import { AppHelper } from '../utilities/app.helper';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ChatService extends BaseService{
  public baseUrl = 'live-support';
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }
  
  public getMyMessages(take: number, skip: number): Observable<any[]> {
    return this.get<any[]>(`${this.baseUrl}/messages`, {
      params: {     
        take,
        skip,   
      }
    }).pipe(
      map((res: any) => {
        return res!.data;
      })
    );
  }

  public sendMessage(message: Partial<AppConstant.Message>) : Observable<any> {
    const payload = new FormData();
    payload.append('content', AppHelper.UtileFunctions.encodeBase64(message.content || ''));
    payload.append('type', message?.type || '');
    payload.append('senderType', message.senderType || '');

    if (message?.attachments)
      message?.attachments.forEach((file: File) => {
        const fileName =  AppHelper.UtileFunctions.encodeFileName(file.name)
        payload.append('supportCaseAttachments', file, fileName);
      });

    return this.post<any, any>(`${this.baseUrl}/messages`, payload).pipe(
      map((res: any) => {
        return res!.data;
      })
    ) 
  }

  public getUnreadLiveMessages(): Observable<any[]> {
    return this.get<any[]>(`${this.baseUrl}/messages/unread`).pipe(
      map((res: any) => {
        return res!.data;
      })
    );
  }
  
  public readAllLiveMessages(): Observable<any[]> {
    return this.patch<any, any>(`${this.baseUrl}/messages/read-all`).pipe(
      map((res: any) => {
        return res!.data;
      })
    );
  }
  
  public readLiveMessage(messageId: number): Observable<any[]> {
    return this.patch<any, any>(`${this.baseUrl}/messages/${messageId}/read`).pipe(
      map((res: any) => {
        return res!.data;
      })
    );
  }
  
  public addEvent(eventType: AppConstant.SupportCaseEvents): Observable<any> {
    return this.post<any, any>(`${this.baseUrl}/messages/events`, {
      type: eventType,
    }).pipe(
      map((res: any) => {
        return res!.data;
      })
    )
  }

  public updateInteractionMesssage(messageId: number, updatedContent: any): Observable<any> {
    return this.post<any, any>(`${this.baseUrl}/messages/${messageId}/update-interaction`, {
      content: updatedContent,
    }).pipe(
      map((res: any) => {
        return res!.data;
      })
    );
  }

  public getCurrentSupportCase(): Observable<any[]> {
    return this.get<any[]>(`${this.baseUrl}/current-support-case`).pipe(
      map((res: any) => {
        return res!.data;
      })
    );
  }
}