import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { filter, Subject, take, takeUntil } from 'rxjs';
import { ChatWindowService } from 'src/app/shared/services/chat-window.service';
import { UserInfoService } from 'src/app/shared/services/user-info.service';
import { AppConstant } from 'src/app/shared/utilities/app.constant';

@Component({
  selector: 'app-chat-live-support',
  templateUrl: './chat-live-support.component.html',
  styleUrls: ['./chat-live-support.component.scss'],
})

export class ChatLiveSupportComponent implements OnInit, OnDestroy {
  @Output()
  emitTotalUnread: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    private chatWindowService: ChatWindowService,
    private userInfoService: UserInfoService
  ) {}

  currentStep$ = this.chatWindowService.currentChatStepSubject$;
  destroy$ = new Subject<void>();

  newMessageText: string;
  timeoutId: any;
  currentUser: any;

  ngOnInit(): void {
    this.connectAgent();
    
    this.userInfoService.userSubject.pipe(
      takeUntil(this.destroy$),
      filter((userObject: any) => !!userObject),
      take(1),
    ).subscribe({
      next: (user: any) => {
        if (user) {
          this.currentUser = user;
        }
      },
    });
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutId);
    this.destroy$.next();
    this.destroy$.complete();
  }
  
  connectAgent() {
    this.chatWindowService.setCurrentChatStep('WAIT')
    this.timeoutId = setTimeout(() => {      
      this.chatWindowService.setCurrentChatStep('CHAT');
    }, 1000);
  }

  onSend() {
    this.chatWindowService.setCurrentStep('SENT_AGENT');
  }

  onKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onSend();
    }
  }
  
  showForm(step: AppConstant.Step) {
    this.chatWindowService.setCurrentStep(step);
  }

  emitUnreadMsg(event: any) {
    this.emitTotalUnread.emit(event);
  }
}
