import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AnimationStyleMetadata } from '@angular/animations';
import { ChatWindowService } from 'src/app/shared/services/chat-window.service';
import { ChatService } from 'src/app/shared/services/chat.service';
import { WebSocketService, WS_MESSAGE_TYPE } from 'src/app/shared/services/web-socket.service';
import { AppConstant } from 'src/app/shared/utilities/app.constant';
import { ConfirmationOverlayComponent } from '../confirmation-overlay/confirmation-overlay.component';

@Component({
  selector: 'app-chat-window',
  templateUrl: './chat-window.component.html',
  styleUrls: ['./chat-window.component.scss'],
})

export class ChatWindowComponent implements OnInit {
  private onReceiveNewMessageBound: Function;
  private onReceiveFlagReadAllBound: Function;
  
  @Input() totalUnreadMsg: number = 0;
  @Input() isShow: boolean;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() emitTotalUnread: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('confirmationOverlay') confirmationOverlay: ConfirmationOverlayComponent;

  currentStep$ = this.chatWindowService.currentStep$;
  isLoading$ = this.chatWindowService.isLoading$;

  constructor(
    private chatWindowService: ChatWindowService, 
    private chatService: ChatService,
    private _io: WebSocketService
  ) {
    this.onReceiveNewMessageBound = this.onReceiveNewMessage.bind(this);
    this.onReceiveFlagReadAllBound = this.onReceiveFlagReadAll.bind(this);
  }
  
  
  ngOnInit(): void {
    this._io.registerMessageHanlder(WS_MESSAGE_TYPE.USER__NEW_MESSAGE, this.onReceiveNewMessageBound);
    this._io.registerMessageHanlder(WS_MESSAGE_TYPE.USER__READ_ALL, this.onReceiveFlagReadAllBound);
  }

  ngOnChanges(changes: SimpleChanges) { 
    if (changes['isShow'] && changes['isShow'].currentValue) {
      if (changes['isShow'].currentValue && this.totalUnreadMsg)
        this.chatWindowService.setCurrentStep('LIVE');
    }
  }
  
  ngOnDestroy(): void {
    this._io.unregisterMessageHanlder(WS_MESSAGE_TYPE.USER__NEW_MESSAGE, this.onReceiveNewMessageBound);
  }

  onReceiveNewMessage(message: any) {
    const curentStep = this.chatWindowService.getCurrentStep();
    if (message && message.senderType != 'system' && ( !curentStep || curentStep != 'LIVE')) {
      this.setTotalUnread();
    }
  }

  onReceiveFlagReadAll(message: any) {
    if (message) {
      this.setTotalUnread(0);
    }
  }
  
  showForm(form: AppConstant.Step) {
    this.chatWindowService.setCurrentStep(form);
  }

  setTotalUnread(total: number | null = null) {
    if (total == null)
        this.totalUnreadMsg = this.totalUnreadMsg + 1;
    else
      this.totalUnreadMsg = total;

    this.emitTotalUnread.emit(this.totalUnreadMsg);
  }
 
  showCloseConfirmation() {
    if (this.chatWindowService.getCurrentStep() === 'LIVE')
      this.confirmationOverlay.show();
    else
      this.closeChatWindow();
  }

  hideChatWindow() {
    this.onClose.emit(false);
    this.chatWindowService.setCurrentStep();
  }

  closeChatWindow() {
    this.onClose.emit(false);
    this.chatWindowService.setCurrentStep();
    this.chatService.addEvent(AppConstant.SupportCaseEvents.USER__LEFT_CHAT).subscribe(() => {});
  }

  cancelClose() {
  }
 
  goBack() {
    this.chatWindowService.setCurrentStep();
  }
}
