<div class="app">
  <div>
    <app-header
      [isDisplaySidebarUserInfomation]="displaySidebarUserInfomation"
      (expandMenu)="expandMenuEvent($event)"
    ></app-header>
  </div>
  <div class="wrapper">
    <app-sidebar [expandMenu$]="expandMenu"></app-sidebar>
    <main
      class="content"
      [ngClass]="{
        'width-login':
          hasRoute('login') ||
          hasRoute('user/team-management') ||
          hasRoute('rig/rig-management') ||
          hasRoute('mailbox/mailbox-management') ||
          hasRoute('help-center') ||
          hasRoute('live-support') ||
          hasRoute('alerts/alerts-management'),
          'expanded-sidebar': expandMenu
      }"
    >
      <section
        class="content__main"
        [ngClass]="{ 'background-login': hasRoute('login') }"
        [ngStyle]="hasRoute('login') ? { 'background-image': 'url(' + image + ')' } : {}"
      >
        <div
          class="wrapper-content"
          [ngClass]="{
            onlyOneWiget:
              hasRoute('login') ||
              hasRoute('user/team-management') ||
              hasRoute('rig/rig-management') ||
              hasRoute('mailbox/mailbox-management') ||
              hasRoute('help-center') ||
              hasRoute('live-support') ||
              hasRoute('alerts/alerts-management'),
            minimizeNotification:
              !(hasRoute('login') ||
                hasRoute('user/team-management') ||
                hasRoute('rig/rig-management') ||
                hasRoute('mailbox/mailbox-management') ||
                hasRoute('help-center') ||
                hasRoute('live-support') ||
                hasRoute('alerts/alerts-management')) && 
                !(isCollapse$ | async) ,
            'expanded-sidebar': expandMenu,
            'expanded-notification-panel': notificationPanelExpanded,
            'expanded-tutorial-panel': tutorialPanelExpanded
          }"
        >
          <div
            *ngIf="
              !(
                hasRoute('login') ||
                hasRoute('user/team-management') ||
                hasRoute('rig/rig-management') ||
                hasRoute('mailbox/mailbox-management') ||
                hasRoute('help-center') ||
                hasRoute('live-support') ||
                hasRoute('alerts/alerts-management')
              )
            "
          >
            <app-notification-list
              [isCollapse]="(isCollapse$ | async) || false"
              [call]="
                !(
                  hasRoute('login') ||
                  hasRoute('user/team-management') ||
                  hasRoute('rig/rig-management') ||
                  hasRoute('mailbox/mailbox-management') ||
                  hasRoute('help-center') ||
                  hasRoute('live-support') ||
                  hasRoute('alerts/alerts-management')
                )
              "
              (minimizeNotification)="changeSizeOfNotification($event)"
            ></app-notification-list>
          </div>
          <div
            class="dynamic-content"
            [ngClass]="{ 'height-login': hasRoute('login') }"
          >
            <ng-content></ng-content>
          </div>
          <app-notification-center
            [ngClass]="{ hidden: !notificationPanelExpanded }"
            *ngIf="notificationPanelExpanded"
            style="height: calc(100vh - 112px)"
          ></app-notification-center>

          <app-tutorial-center
            [ngClass]="{ hidden: !tutorialPanelExpanded }"
            style="height: calc(100vh - 112px)"
          ></app-tutorial-center>
        </div>
      </section>
    </main>
  </div>

  <app-home-user-information
    [displaySidebarUserInfomation]="displaySidebarUserInfomation"
  ></app-home-user-information>

  <ng-container *ngIf="render.isShow">
    <app-home-distribution-list
      [display]="render.isShow"
      [uploadStatus]="render.template"
      [rigName]="data.rigName"
      [rigProject]="data.rigProject"
      [rigOperator]="data.rigOperator"
      [rigJournalId]="data.rigJournalId"
      [rigId]="data.rigId"
      [distributionUrl]="link.distributionUrl"
      [reuploadStatus]="render.reuploadStatus"
      [language]="data.language"
    >
    </app-home-distribution-list>
  </ng-container>

  <app-footer></app-footer>
</div>
<!-- COMMON toast notifications -->
<p-toast
  class="custom-toaster"
  [showTransformOptions]="'translateX(100%)'"
>
  <ng-template let-message pTemplate="message">
    <div class="d-flex flex-column" style="flex-basis: 375px">
      <div class="header-notification">
        <mat-icon
          *ngIf="message.severity === 'success'"
          fontIcon="check_circle"
          style="font-size: 22px"
        ></mat-icon>
        <mat-icon
          *ngIf="message.severity === 'info'"
          fontIcon="error"
          style="font-size: 22px"
        ></mat-icon>
        <mat-icon
          *ngIf="message.severity === 'warning'"
          fontIcon="warning"
          style="font-size: 22px"
        ></mat-icon>
        <mat-icon
          *ngIf="message.severity === 'error'"
          fontIcon="cancel"
          style="font-size: 22px"
        ></mat-icon>
        <p class="sumary-text">{{ message.summary }}</p>
      </div>
      <div class="detail-notification">
        <p class="detail-text">{{ message.detail.split("/br")[0] }}</p>
        <p class="time-text">{{ message.detail.split("/br")[1] }}</p>
      </div>
    </div>
  </ng-template>
</p-toast>

<app-extendInfo></app-extendInfo>

<app-chat-bubble *ngIf="!isSupporter"></app-chat-bubble>

<!-- Loading -->
<app-loading [isLoading]="isLoading"></app-loading>
